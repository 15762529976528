import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
// import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    light: true,
    customProperties: true,
    themes: {
      light: {
        primary: "#A62B1F",
        secondary: "#0388A6",
        accent: "#518c9c",
        error: "#FF304C",
        warning: "#FFC837",
        info: "#04ADBF",
        success: "#0388A6",  
      },
      dark: {
        primary: "#EF2B18",
        secondary: "#05A2C5",
        accent: "#C1D4D9",
        error: "#FF9494",
        warning: "#D9AB73",
        info: "#04ADBF",
        success: "#03A0C3",
      },
    },
  },
});
