//                  _       _   _   ______               _                 _
//       /\        (_)     | | (_) |  ____|             | |               | |
//      /  \   __ _ _ _ __ | |_ _  | |__ _ __ ___  _ __ | |_ ___ _ __   __| |
//     / /\ \ / _` | | '_ \| __| | |  __| '__/ _ \| '_ \| __/ _ \ '_ \ / _` |
//    / ____ \ (_| | | | | | |_| | | |  | | | (_) | | | | ||  __/ | | | (_| |
//   /_/    \_\__, |_|_| |_|\__|_| |_|  |_|  \___/|_| |_|\__\___|_| |_|\__,_|
//             __/ |
//            |___/
//
// Aginti frontend code - made by humans (in part)
// Copyright � 2021-Present Umberto Giacobbi. All rights reserved.
//
// For more information: www.umbertogiacobbi.biz
//

"use strict";

import Vue from "vue";
import axios from "axios";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

Plugin.install = function (Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
